<template>
  <div>
    <!--begin::customer-->
    <!--    <div class="card card-custom">-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>
      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('customers.customers_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="card-body">-->
      <!--        <div class="card-body p-0">-->
      <b-tabs class="nav-custom-link" content-class="mt-3">
        <b-tab :title="$t('customers.basic_information')" active @click="toggleTab('basic_information')">
          <div class="row">
            <div class="col-md-4">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('customers.full_name') }}<span class="text-danger">*</span></label>
                    <input
                        v-model="data.fullname"
                        :class="validation && validation.fullname ? 'is-invalid' : ''"
                        class="form-control"
                        type="text"
                    />
                    <span v-if="validation && validation.fullname"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.fullname[0] }}
                    </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('customers.code') }}</label>
                    <input
                        v-model="data.customer_code"
                        :class="validation && validation.customer_code ? 'is-invalid' : ''"
                        :disabled="true"
                        class="form-control"
                        type="text"
                    />
                    <span v-if="validation && validation.customer_code"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.customer_code[0] }}
                    </span>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('customers.branch') }}</label>
                    <div class="input-group">

                      <multiselect v-model="branch"
                                   :multiple="false"
                                   :options="branches"
                                   :placeholder="$t('customers.branch')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   label="name"
                                   track-by="id">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/branches" target="_blank"><i class="fa fa-plus"
                                                                                                style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.branch_id"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.branch_id[0] }}
                    </span>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.customer_type') }}<span class="text-danger">*</span></label>
                    <b-form-group v-slot="{ ariaDescribedby }" :label="''">
                      <b-form-radio v-model="data.customer_type" :aria-describedby="ariaDescribedby"
                                    name="customer_type" value="1">{{ $t('customers.individual') }}
                      </b-form-radio>
                      <b-form-radio v-model="data.customer_type" :aria-describedby="ariaDescribedby"
                                    name="customer_type" value="2">{{ $t('customers.business') }}
                      </b-form-radio>
                    </b-form-group>
                    <span v-if="validation && validation.customer_type"
                          class="fv-plugins-message-container invalid-feedback">
                      {{ validation.customer_type[0] }}
                    </span>
                  </div>

                  <template>
                    <div v-if="data.customer_type == 2" class="col-lg-12 mb-5">
                      <label>{{ $t('customers.commercial_register') }}</label>
                      <input v-model="data.commercial_register" :class="validation && validation.commercial_register ? 'is-invalid' : ''" class="form-control"
                             type="text"/>
                      <span v-if="validation && validation.commercial_register"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.commercial_register[0] }}
                      </span>
                    </div>
                    <div class="col-lg-12 mb-5">
                      <label>{{ $t('customers.tax_register') }}</label>
                      <input v-model="data.tax_register" :class="validation && validation.tax_register ? 'is-invalid' : ''" class="form-control"
                             type="text"/>
                      <span v-if="validation && validation.tax_register"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.tax_register[0] }}
                      </span>
                    </div>
                  </template>

                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('customers.type') }}</label>
                    <div class="input-group">
                      <select id="" v-model="data.type" :class=" validation && validation.type ? 'is-invalid' : ''" class="custom-select"
                              name="">
                        <option :value="null">{{ $t('not_specified') }}</option>
                        <option v-for="(row, index) in types_list" :key="index" :value="row.id">{{ row.title }}</option>
                      </select>
                      <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">{
                        validation.type[0] }}</span>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('customers.classification') }}</label>
                    <div class="input-group">
                      <select id="" v-model="data.classification_id" :class=" validation && validation.classification_id ? 'is-invalid' : ''" class="custom-select"
                              name="">
                        <option v-for="(row, index) in classifications_list" :key="index" :value="row.id">{{
                            row.name
                          }}
                        </option>
                      </select>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/customers/customers-status" target="_blank"><i
                            class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                      <span v-if="validation && validation.classification_id"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.classification_id[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('status') }}:</label>
                    <b-form-checkbox v-model="data.is_active" name="check-button" size="lg" switch></b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card card-custom">
                <div class="card-header pl-6 pr-6">
                  <div class="card-title">
                    <h4 class="m-0">{{ $t('customers.contact_information') }}</h4>
                  </div>
                </div>
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.mobile') }}</label>
                    <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"
                                   v-on:country-changed="mobileChanged"></vue-tel-input>
                    <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.mobile[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('customers.phone') }}</label>
                    <!--                                        <input-->
                    <!--                                                type="number"-->
                    <!--                                                class="form-control"-->
                    <!--                                                :class="validation && validation.phone ? 'is-invalid' : ''"-->
                    <!--                                                v-model="data.phone"/>-->
                    <vue-tel-input v-model="data.phone" :class="validation && validation.phone ? 'is-invalid' : ''"
                                   v-on:country-changed="phoneChanged"></vue-tel-input>
                    <span v-if="validation && validation.phone" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.phone[0] }}
                    </span>
                  </div>
                  <div class="col-lg-6  mb-5">
                    <label>{{ $t('customers.email') }}</label>
                    <div class="input-group">
                      <input v-model="data.email" :class="validation && validation.email ? 'is-invalid' : ''"
                             class="form-control" type="email"/>
                      <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.email[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('price_type') }}</label>
                    <div class="input-group">
                      <select id="" v-model="data.price_type" :class=" validation && validation.price_type ? 'is-invalid' : ''" class="custom-select"
                              name="">
                        <option :value="null">{{ $t('not_specified') }}</option>
                        <option v-for="(row, index) in price_types_list" :key="index" :value="row.id">{{
                            row.title
                          }}
                        </option>
                      </select>
                      <span v-if="validation && validation.price_type"
                            class="fv-plugins-message-container invalid-feedback">{{ validation.price_type[0] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-header pl-6 pr-6">
                  <div class="card-title">
                    <h4 class="m-0">{{ $t('customers.address_information') }}</h4>
                  </div>
                </div>
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.countries') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="country"
                          :class="validation && validation.country_code ? 'is-invalid' : ''"
                          :internal-search="false"
                          :multiple="false"
                          :options="countries"
                          :placeholder="$t('branches.country')"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          :taggable="false"
                          label="name"
                          track-by="code2"
                          @search-change="getCountries($event)">
                      </multiselect>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{
                        $t('type_at_least_three_letters') + ' ' + $t('branches.country')
                      }}.</span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.cities') }}</label>
                    <div class="input-group">
                      <multiselect
                          v-model="city"
                          :class="validation && validation.city ? 'is-invalid' : ''"
                          :multiple="false"
                          :options="cities"
                          :placeholder="$t('branches.city')"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          :taggable="true"
                          label="name"
                          track-by="code2"
                      >
                      </multiselect>
                      <div class="input-group-prepend">
                        <a :href="data.country_code?'/settings/cities/'+data.country_code:'#'" class="btn btn-primary"
                           target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <!-- <span class="form-text text-muted">Please enter your postal_code</span> -->
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.state') }}</label>
                    <div class="input-group">
                      <input v-model="data.state" :class="validation && validation.state ? 'is-invalid' : ''"
                             class="form-control" type="text"/>
                      <span v-if="validation && validation.state" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.state[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.postal_code') }}</label>
                    <div class="input-group">
                      <input
                          v-model="data.postal_code"
                          :class="
                                 validation && validation.postal_code
                                 ? 'is-invalid'
                                 : ''"
                          class="form-control"
                          type="text"
                      />
                      <span
                          v-if="validation && validation.postal_code"
                          class="fv-plugins-message-container invalid-feedback"
                      >
                        {{ validation.postal_code[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('national_address') }}</label>
                    <div class="input-group">
                      <input
                          v-model="data.address_1"
                          :class="
                                 validation && validation.address_1 ? 'is-invalid' : ''
                                 "
                          class="form-control"
                          type="text"
                      />
                      <span
                          v-if="validation && validation.address_1"
                          class="fv-plugins-message-container invalid-feedback"
                      >
                        {{ validation.address_1[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('customers.address_2') }}</label>
                    <div class="input-group">
                      <input v-model="data.address_2" :class=" validation && validation.address_2 ? 'is-invalid' : ''"
                             class="form-control" type="text"/>
                      <span v-if="validation && validation.address_2"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation.address_2[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div class="row mt-5">
            <div class="col-md-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('customers.contact_list') }}</h6>
                <button class="btn btn-primary btn-sm" type="button" @click="addContactRowToList">{{
                    $t('add_more')
                  }}
                </button>
              </div>
              <div class="bg-white pb-5">
                <table class="table table-row-bordered" @keyup.alt.enter="addContactRowToList"
                       @keyup.alt.46="removeContactRowFromList(0)">
                  <thead>
                  <tr>
                    <th>{{ $t('customers.first_name') }}</th>
                    <th>{{ $t('customers.last_name') }}</th>
                    <th>{{ $t('customers.email') }}</th>
                    <th>{{ $t('customers.telephone') }}</th>
                    <th>{{ $t('customers.mobile') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in contact_list" :key="index">
                    <td><input v-model="row.first_name" class="form-control" type="text"></td>
                    <td><input v-model="row.last_name" class="form-control" type="text"></td>
                    <td>
                      <input v-model="row.email" :class="validation && validation[`contact_list.${index}.email`] ? 'is-invalid' : ''" class="form-control"
                             type="text">
                      <span v-if="validation && validation[`contact_list.${index}.email`]"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`contact_list.${index}.email`][0] }}
                      </span>
                    </td>
                    <td>
                      <vue-tel-input v-model="row.telephone" :class="validation && validation[`contact_list.${index}.telephone`] ? 'is-invalid' : ''"
                                     v-on:country-changed="phoneRepeaterChanged(index, $event)"></vue-tel-input>
                      <span v-if="validation && validation[`contact_list.${index}.telephone`]"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`contact_list.${index}.telephone`][0] }}
                      </span>
                    </td>
                    <td>
                      <vue-tel-input v-model="row.mobile" :class="validation && validation[`contact_list.${index}.mobile`] ? 'is-invalid' : ''"
                                     v-on:country-changed="mobileRepeaterChanged(index, $event)"></vue-tel-input>
                      <span v-if="validation && validation[`contact_list.${index}.mobile`]"
                            class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`contact_list.${index}.mobile`][0] }}
                      </span>
                    </td>
                    <!--                                                <td><input v-model="row.telephone" type="text" class="form-control"></td>-->
                    <!--                                                <td><input v-model="row.mobile" type="text" class="form-control"></td>-->
                    <td>
                      <v-icon v-if="contact_list.length > 1" small style="color: #dc3545;"
                              @click="removeContactRowFromList(index)">mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :disabled="!idEditing" :title="$t('customers.accounting_information')"
               @click="toggleTab('accounting_information')">
          <div class="card card-custom">
            <div class="card-body">
              <div class="form-group row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('customers.currencies') }}</label>
                  <div class="input-group">
                    <select id="currencies" v-model="data.currency_id" class="custom-select" name="">
                      <option v-for="row in currencies" :key="row.id" :value="row.id">
                        {{ row.name }}
                      </option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus"
                                                                                                style="padding: 0"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('customers.languages') }}</label>
                  <div class="input-group">
                    <select
                        id="languages"
                        v-model="data.language_id"
                        class="custom-select"
                        name=""
                    >
                      <option
                          v-for="row in languages"
                          :key="row.id"
                          :value="row.id"
                      >
                        {{ row.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--                        <div class="col-lg-6 mb-5">-->
                <!--                           <label>{{$t('customers.categories')}}</label>-->
                <!--                           <div class="input-group mb-3">-->
                <!--                                <multiselect v-model="data.category"-->
                <!--                                    :placeholder="$t('customers.categories')"-->
                <!--                                    label="name" track-by="id"-->
                <!--                                    :options="tags_list"-->
                <!--                                    :multiple="true"-->
                <!--                                    :taggable="true"-->
                <!--                                    @tag="addTag"-->
                <!--                                    :show-labels="false"-->
                <!--                                    :show-no-options="false"-->
                <!--                                    :show-no-results="false">-->
                <!--                                </multiselect>-->
                <!--                                <div class="input-group-prepend">-->
                <!--                                    <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
                <!--                                </div>-->
                <!--                           </div>-->
                <!--                           <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('customers.categories') }}.</span>-->
                <!--                           <span v-if="validation && validation.tags" class="fv-plugins-message-container invalid-feedback">-->
                <!--                           {{ validation.tags[0] }}-->
                <!--                           </span>-->
                <!--                        </div>-->
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('customers.sales_man') }}</label>
                  <div class="input-group mb-3">
                    <multiselect v-model="sales_man"
                                 :multiple="false"
                                 :options="employees"
                                 :placeholder="$t('customers.sales_man')"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :taggable="false"
                                 label="name"
                                 track-by="id"
                                 @search-change="getEmployees($event)">
                    </multiselect>
                    <div v-if="$can('employees.create')" class="input-group-prepend">
                      <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i
                          class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span class="form-text text-muted" style="text-align:end">{{
                      $t('type_at_least_three_letters') + ' ' + $t('customers.sales_man')
                    }}.</span>
                  <span v-if="validation && validation.sales_man_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.sales_man_id[0] }}
                  </span>
                </div>
                <div v-if="accounts_list && accounts_list.length" class="col-lg-6 mb-5">
                  <label>&ensp;</label>
                  <b-form-checkbox v-model="use_tree_accounting" name="check-button" size="lg" switch>
                    {{ $t('customers.create_special_account_in_tree_accounting') }}
                  </b-form-checkbox>
                </div>
                <div v-if="use_tree_accounting" class="col-lg-6 mb-5">
                  <label>{{ $t('customers.account') }}</label>
                  <treeselect
                      :class="validation && validation.account_id ? 'is-invalid' : ''"
                      :load-options="loadOptions"
                      :multiple="false"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :options="accounts_list"
                      :placeholder="$t('Select')"
                      :searchable="true"
                      :show-count="true"
                      :value="data.parent_account_id"
                      @input="updateValueAccount">
                  </treeselect>
                  <span v-if="validation && validation.account_id"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.account_id[0] }}
                  </span>
                </div>

                <div class="col-lg-12 mb-5">
                  <label>{{ $t('customers.related_customers') }}</label>
                  <div class="input-group">

                    <multiselect v-model="related_customers_obj"
                                 :multiple="true"
                                 :options="related_customers_list"
                                 :placeholder="$t('customers.related_customers')"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :taggable="false"
                                 label="name"
                                 track-by="id"
                                 @search-change="getCustomers($event)">
                    </multiselect>
                  </div>
                  <span v-if="validation && validation.related_customers"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.related_customers[0] }}
                  </span>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-lg-12">
                  <label>{{ $t('customers.notes') }}</label>
                  <div class="input-group">
                    <textarea v-model="data.notes" class="form-control" cols="30" rows="5"></textarea>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <label>{{ $t('customers.price_list') }}</label>
                  <div class="input-group mb-3">
                    <multiselect v-model="inner_price_list"
                                 :multiple="false"
                                 :options="prices_lists"
                                 :placeholder="$t('customers.price_list')"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 :taggable="true"
                                 label="name"
                                 track-by="id">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/items/price-lists/create" target="_blank"><i class="fa fa-plus"
                                                                                                     style="padding: 0"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ $t('customers.is_default') }}:</label>
                  <b-form-checkbox v-model="data.is_default" name="check-button" size="lg" switch></b-form-checkbox>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <hr>
                </div>
                <div class="col-md-12">
                  <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                    <h6 class="my-auto text-white">{{ $t('customers.balances') }}</h6>
                    <!--                                            <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{$t('add_more')}}</button>-->
                  </div>
                  <div class="table-responsive">
                    <!--                                            <table class="table table-row-bordered" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('customers.balance') }}</th>
                        <th>{{ $t('suppliers.debit') }}</th>
                        <th>{{ $t('customers.currency') }}</th>
                        <th>{{ $t('customers.last_validated_date') }}</th>
                        <th></th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="items_list.length > 0">
                        <tr v-for="(row, index) in items_list" :key="index">

                          <td><input v-model="row.credit" :disabled="true" class="form-control" min="0.01" step="0.01"
                                     type="number"></td>
                          <td><input v-model="row.debit" :disabled="true" class="form-control" min="0.01" step="0.01"
                                     type="number"></td>

                          <td>
                            <select v-model="row.currency_id" :disabled="true" class="custom-select" name="">
                              <option v-for="cc in currencies" :key="cc.id" :value="cc.id">{{ cc.name }}</option>
                            </select>
                          </td>
                          <td>
                            {{ row.last_validated_date }}
                          </td>
                          <td>
                            <v-icon v-if="items_list.length > 1 && !row.id" color="danger" small style="color: #dc3545;"
                                    @click="removeItemRowFromList(index)">mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_balances') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :disabled="!idEditing" :title="$t('customers.address_info')" @click="toggleTab('address_info')">
          <div class="card card-custom">
            <div class="card-body row">
              <address-info :customer-id="idEditing"></address-info>
            </div>
          </div>
        </b-tab>
        <!--                  <b-tab :title="$t('customers.opening_balance')" :disabled="!idEditing" @click="toggleTab('opening_balance')">-->
        <!--                     <div class="form-group row">-->
        <!--                        <div class="col-lg-6 mt-5">-->
        <!--                           <label>{{$t('customers.amount')}}</label>-->
        <!--                           <input type="text" v-model="data.open_amount" class="form-control" :class="validation && validation.open_amount ? 'is-invalid' : ''"/>-->
        <!--                           <span v-if="validation && validation.open_amount" class="fv-plugins-message-container invalid-feedback">-->
        <!--                           {{ validation.open_amount[0] }}-->
        <!--                           </span>-->
        <!--                        </div>-->
        <!--                        <div class="col-lg-6 mt-5">-->
        <!--                           <label>{{$t('customers.date')}}</label>-->
        <!--                           <input type="date" v-model="data.open_amount_date" class="form-control" :class="validation && validation.open_amount_date ? 'is-invalid' : ''"/>-->
        <!--                           <span v-if="validation && validation.open_amount_date" class="fv-plugins-message-container invalid-feedback">-->
        <!--                           {{ validation.open_amount_date[0] }}-->
        <!--                           </span>-->
        <!--                        </div>-->
        <!--                        <div class="col-lg-6 mt-5">-->
        <!--                           <label>{{$t('customers.currency')}}</label>-->
        <!--                           <div class="input-group">-->
        <!--                                <select name="" id="currency_id_open_amount" v-model="data.currency_id_open_amount" class="custom-select" :class="validation && validation.currency_id_open_amount ? 'is-invalid' : ''">-->
        <!--                                    <option v-for="row in currencies" :value="row.id" :key="row.id">-->
        <!--                                        {{ row.name }}-->
        <!--                                    </option>-->
        <!--                                </select>-->
        <!--                                <div class="input-group-prepend">-->
        <!--                                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
        <!--                                </div>-->
        <!--                           </div>-->
        <!--                           <span v-if="validation && validation.currency_id_open_amount" class="fv-plugins-message-container invalid-feedback">-->
        <!--                           {{ validation.currency_id_open_amount[0] }}-->
        <!--                           </span>-->
        <!--                        </div>-->
        <!--                     </div>-->
        <!--                  </b-tab>-->

        <b-tab :disabled="!idEditing" :title="$t('customers.notes_attachments')"
               @click="toggleTab('notes_attachments')">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="form-group row">
                <div class="col-lg-6 mt-5">
                  <label>{{ $t('customers.date') }}</label>
                  <input v-model="data.note_date" :class="validation && validation.note_date ? 'is-invalid' : ''" class="form-control"
                         type="date"/>
                  <span v-if="validation && validation.note_date" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.note_date[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mt-5">
                  <label>{{ $t('customers.time') }}</label>
                  <input v-model="data.note_time" :class="validation && validation.note_time ? 'is-invalid' : ''" class="form-control"
                         type="time"/>
                  <span v-if="validation && validation.note_time" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.note_time[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mt-5">
                  <label>{{ $t('customers.action') }}</label>
                  <div class="input-group">
                    <select id="action" v-model="data.action" :class="validation && validation.action ? 'is-invalid' : ''" class="custom-select"
                            name="">
                      <option v-for="row in actions" :key="row.id" :value="row.id">
                        {{ row.name }}
                      </option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/procedures" target="_blank"><i class="fa fa-plus"
                                                                                                style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.action" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.action[0] }}
                  </span>
                </div>
                <!--                <div class="col-lg-6 mt-5">-->
                <!--                  <label>{{ $t('customers.status') }}</label>-->
                <!--                  <div class="input-group">-->

                <!--                    <select name="" id="status" v-model="data.customer_status" class="custom-select" :class="validation && validation.customer_status ? 'is-invalid' : ''">-->
                <!--                      <option v-for="row in status_list" :value="row.id" :key="row.id">-->
                <!--                        {{ row.customer }}-->
                <!--                      </option>-->
                <!--                    </select>-->
                <!--                    <div class="input-group-prepend">-->
                <!--                      <a class="btn btn-primary" href="/customers/customers-status" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  <span v-if="validation && validation.customer_status" class="fv-plugins-message-container invalid-feedback">-->
                <!--                           {{ validation.customer_status[0] }}-->
                <!--                           </span>-->
                <!--                </div>-->
                <div class="col-lg-12 mt-5">
                  <b-form-checkbox v-model="data.share_with_client" name="check-button" size="lg" switch>
                    {{ $t('customers.share_with_client') }}
                  </b-form-checkbox>
                </div>
                <div class="col-lg-12 mt-5">
                  <label>{{ $t('customers.notes') }}</label>
                  <vue-editor v-model="data.note_details"></vue-editor>
                  <span v-if="validation && validation.note_details"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation.note_details[0] }}
                  </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12 pt-8">
                  <h5>{{ $t('customers.attachments') }}</h5>
                </div>
                <div class="col-lg-12 mt-10">
                  <upload-file
                      v-if="reloadUploadAttachment"
                      :inner-id="'attachment'"
                      :placeholder="$t('customers.upload_attachment')"
                      :start-link="'base'"
                      :upload="dir_upload"
                      :use-preview="false"
                      v-bind:valueprop="{name:'attachment',path: attachment_url}" @file="listenerAttachment">
                  </upload-file>
                </div>
                <div class="col-lg-12 mt-5">
                  <div class="form-group">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>{{ $t('customers.file') }}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(att, index) in attachment_list" :key="index">
                        <td>
                          <a :href="att.attachment_url" class="btn btn-link">{{ $t('customers.file_link') }}
                            ({{ att.attachment }})</a>
                        </td>
                        <td class="text-center">
                          <v-icon class="text-danger" @click="removeAttachmentFromList(index)">mdi-delete</v-icon>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :disabled="!idEditing" :title="$t('customers.payment_credit')" @click="toggleTab('payment_credit')">
          <div class="card card-custom">
            <div class="card-body row">
              <customer-payment-credit :customer-id.sync="idEditing"
                                       :customer-name.sync="data.fullname"></customer-payment-credit>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="$can('customers.min_max_credit_debit')" :disabled="!idEditing"
               :title="$t('customers.credit_and_debit')" @click="toggleTab('credit_and_debit')">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('customers.minimum_credit') }}</label>
                <input v-model="data.minimum_credit" :class="validation && validation.minimum_credit ? 'is-invalid' : ''" class="form-control" min="0"
                       type="number" @input="positiveNumber('minimum_credit')"/>
                <span v-if="validation && validation.minimum_credit"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.minimum_credit[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('customers.maximum_credit') }}</label>
                <input v-model="data.maximum_credit" :class="validation && validation.maximum_credit ? 'is-invalid' : ''" class="form-control" min="0"
                       type="number" @input="positiveNumber('maximum_credit')"/>
                <span v-if="validation && validation.maximum_credit"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.maximum_credit[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('customers.minimum_debit') }}</label>
                <input v-model="data.minimum_debit" :class="validation && validation.minimum_debit ? 'is-invalid' : ''" class="form-control" min="0"
                       type="number" @input="positiveNumber('minimum_debit')"/>
                <span v-if="validation && validation.minimum_debit"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.minimum_debit[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('customers.maximum_debit') }}</label>
                <input v-model="data.maximum_debit" :class="validation && validation.maximum_debit ? 'is-invalid' : ''" class="form-control" min="0"
                       type="number" @input="positiveNumber('maximum_debit')"/>
                <span v-if="validation && validation.maximum_debit"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.maximum_debit[0] }}
                </span>
              </div>

            </div>
          </div>
        </b-tab>
        <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
          <div class="card card-custom">
            <div class="card-body row">
              <activity-log :id="idEditing" :inner-key="key"></activity-log>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div v-if="tab_name != 'activity_log'" class="pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button v-if="tab_name != 'address_info'" class="btn btn-primary mr-2" type="reset" @click="save()">
            {{ $t('save') }}
          </button>
          <button class="btn btn-secondary" type="reset">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
    <!--      </div>-->
    <!--    </div>-->
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import AddressInfo from "./AddressInfo";
import CustomerCredit from "./CustomerCredit.vue";
import {VueEditor} from "vue2-editor";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import {mapGetters, mapState} from "vuex";
import Vue from "vue";

export default {
  name: "form-customer",
  components: {
    'address-info': AddressInfo,
    VueEditor,
    'customer-payment-credit': CustomerCredit,
    'activity-log': ActivityLog
  },
  data() {
    return {
      dir_upload: 'customers',
      key: 'Customer',
      data: {
        price_type: null,
        fullname: "",
        phone: "",
        mobile: "",
        email: "",
        country_code: "",
        city_id: "",
        state: "",
        postal_code: "",
        address_1: "",
        address_2: "",
        status: "",
        customer_code: "",
        currency_id: "",
        language_id: "",
        category: "",
        notes: "",
        is_default: "",
        is_active: true,
        tags: "",

        open_amount: null,
        balance: null,
        open_amount_date: null,
        currency_id_open_amount: null,

        note_date: null,
        note_time: null,
        action: null,
        customer_status: null,
        share_with_client: false,
        note_details: null,
        price_list: null,
        type: null,
        sales_man_id: null,
        parent_account_id: null,
        classification_id: null,
        branch_id: null,

        customer_type: 1,
        commercial_register: null,
        tax_register: null,
        mobile_country_code: null,
        phone_country_code: null,
        related_customers: [],
        minimum_credit: 0,
        maximum_credit: 0,
        minimum_debit: 0,
        maximum_debit: 0,

      },
      price_types_list: [],
      sales_man: null,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      checkedValue: "",
      isEditing: false,
      countries: [],
      cities: [],
      currencies: [],
      languages: [],
      validation: null,
      tags_list: [],
      categories: [],
      prices_lists: [],
      mainRoute: 'customers/customers',
      mainRouteDependency: 'base/dependency',
      tab_name: 'basic_information',
      contact_list_form: {
        first_name: null,
        last_name: null,
        email: null,
        telephone: "",
        mobile: "",
        mobile_country_code: null,
        phone_country_code: null
      },
      contact_list: [],
      attachment_list: [],
      attachment_url: null,
      reloadUploadAttachment: true,
      actions: [],
      status_list: [],
      types_list: [],
      classifications_list: [],
      employees: [],
      country: null,
      city: null,
      inner_price_list: null,

      accounts_list: [],
      use_tree_accounting: false,
      PlanMessage: null,


      items_list: [],
      items_list_form: {
        id: null,
        balance: null,
        currency_id: null,
        last_validated_date: null,
        last_amount: null,
        credit: null,
        debit: null
      },
      branch: null,
      branches: [],
      related_customers_list: [],
      related_customers_obj: [],
      is_pos: null,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {
    "data.mobile": function (val) {
      if (val !== null && val.length < 6) {
        this.data.mobile = null;
      }
    },
    "country": function (val) {
      if (val) {
        this.data.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_code = null;
      }
    },
    "city": function (val) {
      if (val) {
        this.data.city_id = val.id;
      }
    },
    "data.country_code": function (val) {
      if (val) {
        this.getCities(val);
      }
    },
    "data.commercial_register": function (val) {
      if (val) {
        this.data.commercial_register = val.replace(/[^\w\s\][^,]/gi, "");
      }
    },
    // "data.country_code": function (val) {
    //     if (val) {
    //         this.getCities(val);
    //     }
    // },
    "data.fullname": function (val) {
      if (val) {
        this.data.fullname = val.slice(0, 254);
      }
    },
    // 'data.state': function (val) {
    //     if (val) {
    //         this.data.state = val.replace(/[\d]/g, "");
    //     }
    // },
    inner_price_list: function (val) {
      if (val) {
        this.data.price_list = val.id;
      }
    },
    sales_man: function (val) {
      if (val) {
        this.data.sales_man_id = val.id;
      } else {
        this.data.sales_man_id = null;
      }
    },
    use_tree_accounting: function (val) {
      if (!val) {
        this.data.parent_account_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
    related_customers_obj: function (val) {
      if (val && val.length) {
        this.data.related_customers = val.map(row => row.id);
      } else {
        this.data.related_customers = [];
      }
    },
  },
  methods: {
    positiveNumber(attribute) {
      if (isNaN(this.data[attribute]) || !Number(this.data[attribute])) {
        this.data[attribute] = 0.0; // Set a default value of 0.1 if it's not a valid number
      } else if (parseFloat(this.data[attribute]) < 0.0) {
        this.data[attribute] = 0.0; // Set a minimum value of 0.1
      }
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let that = this;
      let _data = this.data;
      _data.balance = this.data.open_amount;
      this.is_pos = (this.data.type == 1 ? 'point_of_sale' : null);
      // _data.price_list = _data.inner_price_list?_data.inner_price_list.id:null;
      this.contact_list.map((row) => {
        if (row.telephone.length < 5) {
          row.telephone = '';
        }
        if (row.mobile.length < 5) {
          row.mobile = '';
        }
        return row;
      });
      ApiService.post(`${this.mainRoute}`, {
        tab_name: that.tab_name,
        contact_list: that.contact_list,
        attachments: that.attachment_list,
        items_list: that.items_list,
        is_pos: that.is_pos,
        ..._data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            Vue.prototype.$postStatus = true;
            this.$router.push({name: 'customers.index'});
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let that = this;
      let _data = this.data;
      // _data.price_list = _data.inner_price_list?_data.inner_price_list.id:null;
      _data.balance = this.data.open_amount;
      this.is_pos = (this.data.type == 1 ? 'point_of_sale' : null);

      this.contact_list.map((row) => {
        if (row.telephone.length < 5) {
          row.telephone = '';
        }
        if (row.mobile.length < 5) {
          row.mobile = '';
        }
        return row;
      });

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        tab_name: that.tab_name,
        contact_list: that.contact_list,
        attachments: that.attachment_list,
        items_list: that.items_list,
        is_pos: that.is_pos,
        ..._data,
      })
          .then((response) => {
            Vue.prototype.$postStatus = true;
            this.validation = null;
            // this.$router.push({name: 'customers.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.fullname = response.data.data.fullname;
        this.data.phone = response.data.data.phone;
        this.data.mobile = response.data.data.mobile;
        this.data.mobile_country_code = response.data.data.mobile_country_code;
        this.data.phone_country_code = response.data.data.phone_country_code;
        this.data.email = response.data.data.email;
        this.data.price_type = response.data.data.price_type;
        this.data.country_code = response.data.data.country_code;
        this.data.city_id = response.data.data.city_id;

        this.data.customer_type = response.data.data.customer_type;
        this.data.commercial_register = response.data.data.commercial_register;
        this.data.tax_register = response.data.data.tax_register;

        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.data.state = response.data.data.state;
        this.data.postal_code = response.data.data.postal_code;
        this.data.address_1 = response.data.data.address_1;
        this.data.address_2 = response.data.data.address_2;
        this.data.is_active = response.data.data.is_active;
        this.data.customer_code = response.data.data.customer_code;
        this.data.currency_id = response.data.data.currency_id;
        this.data.language_id = response.data.data.language_id;
        this.data.category = response.data.data.category;
        this.data.notes = response.data.data.notes;
        this.data.is_default = response.data.data.is_default;
        this.data.price_list = response.data.data.price_list;
        this.inner_price_list = response.data.data.priceList;

        this.data.open_amount = response.data.data.open_amount;
        this.data.balance = response.data.data.balance;
        this.data.open_amount_date = response.data.data.open_amount_date;
        this.data.currency_id_open_amount = response.data.data.currency_id_open_amount;
        this.data.note_date = response.data.data.note_date;
        this.data.note_time = response.data.data.note_time;
        this.data.action = response.data.data.action;
        this.data.customer_status = response.data.data.customer_status;
        this.data.share_with_client = response.data.data.share_with_client;
        this.data.note_details = response.data.data.note_details;
        this.data.type = response.data.data.type;
        this.data.sales_man_id = response.data.data.sales_man_id;
        this.sales_man = response.data.data.sales_man;
        this.data.parent_account_id = response.data.data.parent_account_id;
        this.data.classification_id = response.data.data.classification_id;
        this.data.branch_id = response.data.data.branch_id;

        this.data.minimum_credit = response.data.data.minimum_credit;
        this.data.maximum_credit = response.data.data.maximum_credit;
        this.data.minimum_debit = response.data.data.minimum_debit;
        this.data.maximum_debit = response.data.data.maximum_debit;

        this.branch = response.data.data.branch;
        this.related_customers_obj = response.data.data.related_customers_obj;
        if (response.data.data.parent_account_id) {
          this.use_tree_accounting = true;
        }
        this.contact_list = response.data.data.contact_list ? response.data.data.contact_list : [];
        this.attachment_list = response.data.data.attachments ? response.data.data.attachments : [];

        if (response.data.data.contact_list && response.data.data.contact_list.length <= 0) {
          this.addContactRowToList();
        }

        /*if (!response.data.data.items_list || (response.data.data.items_list && response.data.data.items_list.length <= 0)) {
          this.addItemRowToList();
        }else {*/
        this.items_list = response.data.data.items_list ? response.data.data.items_list : [];
        // }

      });
    },

    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/1").then((response) => {
        this.data.customer_code = response.data.data.code;
      });
    },


    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/countries`, {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getLanguages() {
      ApiService.get(this.mainRouteDependency + "/languages").then((response) => {
        this.languages = response.data.data;
      });
    },

    getCities(id) {
      if (id)
        ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
          this.cities = response.data.data;
        });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: Math.floor((Math.random() * 10000000))
      }
      this.tags_list.push(tag)
      this.data.category.push(tag)
    },
    updateValue(value) {
      this.data.categories = value.map((row) => {
        return {id: row.id, name: row.label}
      });
    },
    updateValueAccount(value) {
      this.data.parent_account_id = value;
    },
    loadOptions() {
    },
    // getCategories() {
    //     ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
    //         this.categories = response.data.data;
    //     });
    // },
    getPricesLists() {
      ApiService.get(this.mainRouteDependency + "/pricesLists").then((response) => {
        this.prices_lists = response.data.data;
      });
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },

    addContactRowToList() {
      this.contact_list.unshift(this.contact_list_form);
      this.contact_list_form = {
        first_name: null,
        last_name: null,
        email: null,
        telephone: "",
        mobile: "",
        mobile_country_code: null,
        phone_country_code: null
      };
    },
    removeContactRowFromList(index) {
      this.contact_list.splice(index, 1);
    },
    listenerAttachment(event) {
      if (event) {
        this.addAttachmentToList(event.pathDB, event.name);
      }
    },
    addAttachmentToList(attachment_url, attachment) {
      this.reloadUploadAttachment = false;
      this.attachment_url = null;
      this.attachment_list.push({id: null, attachment: attachment, attachment_url: attachment_url});
      this.reloadUploadAttachment = true;
    },
    removeAttachmentFromList(index) {
      this.attachment_list.splice(index, 1);
    },
    phoneChanged(obj) {
      if (obj) {
        this.data.phone_country_code = obj.dialCode
      } else {
        this.data.phone_country_code = null;
      }
    },
    mobileChanged(obj) {
      if (obj) {
        this.data.mobile_country_code = obj.dialCode;
      } else {
        this.data.mobile_country_code = null;
      }
    },
    phoneRepeaterChanged(index, obj) {
      if (obj) {
        this.contact_list[index].phone_country_code = obj.dialCode;
      } else {
        this.contact_list[index].phone_country_code = null;
      }
    },
    mobileRepeaterChanged(index, obj) {
      if (obj) {
        this.contact_list[index].mobile_country_code = obj.dialCode;
      } else {
        this.contact_list[index].mobile_country_code = null;
      }
    },
    getActions() {
      ApiService.get(this.mainRouteDependency + "/procedures_customer").then((response) => {
        this.actions = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/customers_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getTypesList() {
      ApiService.get(this.mainRouteDependency + "/customers_types").then((response) => {
        this.types_list = response.data.data;
      });
    },
    getClassifications() {
      ApiService.get(this.mainRouteDependency + "/customer-classifications").then((response) => {
        this.classifications_list = response.data.data;
        let current_default = response.data.data.find((row) => row.is_default);
        if (!this.data.classification_id && current_default) {
          this.data.classification_id = current_default.id;
        }
      });
    },

    getEmployees(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/employees`, {params: {filter: filter}}).then((response) => {
          this.employees = response.data.data;
        });
      } else {
        this.employees = [];
      }
    },
    getAccounts() {
      ApiService.get(this.mainRouteDependency + `/account_parents`, {
        params: {
          checkDisabled: 1,
          key: 'clients'
        }
      }).then((response) => {
        this.accounts_list = response.data.data;
      });
    },

    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/clients`).then((response) => {
        this.data.parent_account_id = response.data.data.id;
      });
    },
    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/customers`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },

    addItemRowToList() {
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {
        id: null,
        balance: null,
        currency_id: null,
        last_validated_date: null,
        last_amount: null,
        credit: null,
        debit: null
      };
    },
    removeItemRowFromList(index) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },

    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
        if (!this.idEditing) {
          this.branch = this.branches.find(option => option.is_default == 1);
        }

      });
    },
    getPriceListType() {
      ApiService.get(this.mainRouteDependency + "/price_type").then((response) => {
        this.price_types_list = response.data.data;
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.related_customers_list = response.data.data;
        });
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.data.country_code = response.data.data.country_id;
        this.data.city_id = response.data.data.city_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.currency_id_open_amount = response.data.data.currency_id;

        this.branch = response.data.data.branch;
        this.data.branch_id = response.data.data.branch_id;

      });
    }

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.customers_management"),
      route: '/customers/customers'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    if (this.idEditing) {
      this.getData();
    } else {
      this.getPlanMessage();
      if (this.contact_list.length <= 0) {
        this.addContactRowToList();
      }
      this.getCode();
      this.defaultDataForUser();
    }
    // this.getCountries();
    this.getCurrencies();
    this.getLanguages();
    this.getAccounts();
    this.getDefaultAccount();
    this.getBranches();

    // this.getCategories();
    this.getPricesLists();
    this.getActions();
    this.getStatusList();
    this.getTypesList();
    this.getClassifications();
    this.getPriceListType();
    // this.getEmployees();

    this.checkedValue = "active";


  },
};
</script>
